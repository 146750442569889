import { Router } from '@reach/router'
import { ContextProvider } from '@talpor/react-context-manager'
import React from 'react'

import Seo from '../components/Seo'
import { actions, ctx, store } from '../store'

import Agencies from '../scenes/Agencies'
import AgencyDetails from '../scenes/AgencyDetails'
import Practitioners from '../scenes/Practitioners'

const App = () => (
  <ContextProvider actions={actions} store={store} context={ctx}>
    <Seo title="redirect only" />
    <Router>
      <Agencies path="agencies" />
      <AgencyDetails path="/agencies/:id" />
      <Practitioners path="/agencies/:id/practitioners" />
    </Router>
  </ContextProvider>
)

export default App
