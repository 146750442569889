import React, { useEffect, useState } from 'react'
import {
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import ButtonComponent from '../ButtonComponent'

import { useStyles } from './styles'

const SearchDrawer = ({
  isOpen,
  setDrawer,
  search,
  nameSearchValue,
  addressSearchValue,
  isGeolocated,
}) => {
  const classes = useStyles()
  const [addressSearchText, setAddressSearchText] = useState('')
  const [nameSearchText, setNameSearch] = useState(nameSearchValue)

  useEffect(() => {
    setAddressSearchText(
      isGeolocated && !addressSearchValue
        ? 'Current Location'
        : addressSearchValue
    )
  }, [addressSearchValue, isGeolocated, isOpen])

  const setText = (value, type) => {
    if (type === 'name') {
      setNameSearch(value)
    } else {
      if (value === 'clear') {
        setAddressSearchText('')
      } else {
        setAddressSearchText(value)
      }
    }
  }

  const submit = () => {
    search(nameSearchText, addressSearchText)
  }

  return (
    <Drawer
      anchor={'top'}
      open={isOpen}
      onClose={setDrawer}
      className={classes.root}
    >
      <Grid className={classes.drawer}>
        <Grid className={classes.header}>
          <Typography className={classes.title}>Search</Typography>
          <IconButton className={classes.closeButton} onClick={setDrawer}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Grid>
        <Grid className={classes.searchContainer}>
          <Typography className={classes.titles}>Search by location</Typography>
          <TextField
            onClick={() => setText('clear', 'address')}
            className={classes.textField}
            id="search-address-zip"
            onChange={(value) => setText(value.target.value, 'address')}
            placeholder={'Search by Address or ZIP'}
            variant="outlined"
            value={addressSearchText}
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <Typography className={classes.titles}>Search by agency</Typography>
          <TextField
            className={classes.textField}
            onChange={(value) => setText(value.target.value, 'name')}
            placeholder="Search by Agency Name"
            value={nameSearchText}
            variant="outlined"
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
          />
          <ButtonComponent width={120} id="agency-search" text="Search" type="orange" onClick={submit} />
        </Grid>
      </Grid>
    </Drawer>
  )
}

export { SearchDrawer }
