import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles((theme) => ({
  boldText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  checkmarkIcon: {
    color: colors.green,
  },
  combined: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 2vh',
    padding: '2vh 0 1vh',
    width: '92%',
  },
  combinedGrid: {
    alignItems: 'center',
    borderBottom: `1px solid ${colors.lightGrey}`,
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '3vh 0',
    width: '92%',
  },
  daysRow: {
    alignItems: 'center',
    borderBottom: `1px solid ${colors.lightGrey}`,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    padding: '1vh 0',
  },
  daysText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
    margin: '1vh 0',
  },
  distanceText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  fixedContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    width: '100%',
  },
  hoursGrid: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '1vh 4% 0',
    width: '100%',
  },
  hoursText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    margin: '1vh 0',
  },
  italicName: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontStyle: 'italic',
    margin: '1vh 0',
  },
  italicText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontStyle: 'italic',
    margin: '1vh 5px 0 0',
    minWidth: '26%',
  },
  links: {
    alignSelf: 'flex-start',
    color: colors.lightBlue,
    margin: '2vh 4%',
  },
  linkText: {
    alignSelf: 'flex-start',
    color: colors.lightBlue,
    fontSize: '1em',
    fontWeight: 'bold',
    textAlign: 'left',
    textDecoration: 'underline',
  },
  listItemCombined: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5vh 0',
    width: '92%',
  },
  listItemCombinedTitle: {
    color: colors.brandBlue,
    fontSize: '1em',
    margin: '1vh 0',
    fontFamily: 'Mont-Heavy',
    textAlign: 'left',
  },
  listItemCombinedText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    textAlign: 'left',
  },
  listItemText: {
    color: colors.textGrey,
    fontSize: '0.9em',
  },
  markerLabel: {
    marginTop: '-12.5px',
  },
  otherServicesRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '1vh 0',
    width: '48%',
  },
  phoneText: {
    color: colors.lightBlue,
    fontSize: '1em',
    fontWeight: 'bold',
  },
  root: {    
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '60px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
    width: '100%',
  },
  secondaryName: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
  },
  servicesGrid: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '1vh 4%',
    width: '92%',
  },
  servicesNameText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
    marginLeft: '5px',
  },
  shareGrid: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  shareImg: {
    display: 'flex',
    color: 'orange',
    margin: ' 0 2vw 5px',
    width: '4vh',
  },
  title: {
    color: colors.brandBlue,
    letterSpacing: '0',
    fontFamily: 'Mont-Heavy',
    fontSize: '1.1em',
    textTransform: 'capitalize',
  },
  top: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '2.4vh 4% 1vh',
    width: '100%',
  },
  updatedText: {
    alignSelf: 'flex-start',
    borderTop: `1px solid ${colors.lightGrey}`,
    color: colors.textGrey,
    fontSize: '0.9em',
    fontStyle: 'italic',
    margin: '2vh 4% 1vh',
    padding: '2vh 0',
    textAlign: 'left',
    width: '92%',
  },
}))
