import { useReducer, useEffect } from 'react';
import axios from 'axios';

import { actions, reducer } from './reducer';
// import { ctx } from 'store';

// const API_URL = process.env.REACT_APP_API_BASE;

const initialState = {
  data: undefined,
  loading: false,
  error: {},
  success: false,
};

const executeRequest = async (options, dispatch, headers = {}) => {
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  try {
    dispatch({ type: actions.REQUEST_START });
    const { data } = await axios({ ...options, headers });
    dispatch({
      type: actions.REQUEST_END,
      value: {
        data: data,
        success: true,
      },
    });

    return data;
  } catch (err) {
    const { response = {}, message } = err;
    const { data: { code } = {} } = response;

    dispatch({
      type: actions.REQUEST_END,
      value: {
        data: undefined,
        error: {
          code,
          status: response.status,
          message,
        },
      },
    });

    throw err;
  } finally {
    source.cancel();
  }
}

const useDataApi = (path, method, autoExec = false, extraHeaders) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const store = useContext(ctx.store);
  // const { token: accessToken } = store.auth;

  // let headers = { ...extraHeaders };

  // if (accessToken) {
  //   headers = { ...headers, authorization: `Bearer ${accessToken}` };
  // }

  const exec = (localOptions = {}) =>
    executeRequest(
      {
        url: path,
        method: method,
        ...localOptions,
      },
      dispatch,
      // headers
    );

  useEffect(() => {
    if (autoExec) {
      executeRequest(
        {
          url: path,
          method: method,
        },
        dispatch,
        // headers
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, autoExec, method]);

  return [state, exec];
}

export { useDataApi };
