export const filtersGenerator = (ag) => {
  return (ag.SpecialPopulationServed && ag.SpecialPopulationServed !== ' '
    ? ag.SpecialPopulationServed.split(',').map((spc) => ({
        category: 'Population',
        attr: spc.trim(),
      }))
    : []
  )
    .concat(
      ag.Languages.split(',').map((lang) => ({
        category: 'Languages',
        attr: lang.trim(),
      }))
    )
    .concat(
      ag.WheelchairAccess
        ? [{ category: 'Others', attr: 'Wheelchair Accessible' }]
        : []
    )
    .concat(ag.After5 ? [{ category: 'Others', attr: 'Open After 5pm' }] : [])
    .concat(
      ag.WkEnd
        ? [
            {
              category: 'Others',
              attr: 'Available for intake appoinments on the weekend',
            },
          ]
        : []
    )
    .concat(
      (ag.AvailableBeds &&
        ag.AvailableBeds.match(/.*([F,M]:\s?[^0][1-9]).*/)) ||
        (ag.AvailableBedsYouth &&
          ag.AvailableBedsYouth.match(/.*([F,M]:\s?[^0][1-9]).*/))
        ? [
            {
              category: 'Others',
              attr: 'Beds Available',
            },
          ]
        : []
    )
    .concat(
      ag.OPSvcs && ag.OPSvcs !== ''
        ? ag.OPSvcs.split('/').map((item) => {
            if (item.trim() !== '') {
              if (item.trim().includes('OP')) {
                return {
                  category: 'Services',
                  attr: 'Outpatient',
                }
              } else if (item.trim().includes('IOP')) {
                return {
                  category: 'Services',
                  attr: 'Opioid Treatment Program',
                }
              } else if (item.trim().includes('DUI')) {
                return {
                  category: 'Services',
                  attr: 'DUI Program',
                }
              } else if (
                item.trim().includes('A-WM') ||
                item.trim().includes('R-WM')
              ) {
                return {
                  category: 'Services',
                  attr: 'Withdrawal Management (Detox)',
                }
              }
              return null
            }
            return null
          })
        : []
    )
    .concat(
      ag['AvailableBeds'] || ag['AvailableBedsYouth']
        ? `${ag['AvailableBeds']};${ag['AvailableBedsYouth']}`
            .split(';')
            .map((item2) => {
              if (item2.trim() !== '') {
                if (item2.trim().includes('A-WM')) {
                  return {
                    category: 'Services',
                    attr: 'Withdrawal Management (Detox)',
                  }
                } else if (
                  item2.trim().includes('RS(') ||
                  item2.trim().includes('PSRS')
                ) {
                  return {
                    category: 'Services',
                    attr: 'Residential',
                  }
                } else if (item2.trim().includes('RBH')) {
                  return {
                    category: 'Services',
                    attr: 'Recovery Bridge Housing',
                  }
                }
                return null
              }
              return null
            })
        : []
    )
}

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  var radLat1 = (Math.PI * lat1) / 180
  var radLat2 = (Math.PI * lat2) / 180
  var theta = lon1 - lon2
  var radTheta = (Math.PI * theta) / 180
  var dist =
    Math.sin(radLat1) * Math.sin(radLat2) +
    Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta)
  dist = Math.acos(dist)
  dist = (dist * 180) / Math.PI
  dist = dist * 60 * 1.1515
  dist = dist * 1.609344 * 0.621371
  return dist
}

export const getFilters = (dataWithDistance) => {
  const dataWithAttributes = dataWithDistance.filter(
    (ag) => ag.AgencyAttr.length > 0
  )
  let filters = {
    Population: [],
    Services: [],
    Languages: [],
    Others: [],
  }

  for (const item of dataWithAttributes) {
    const filterArray = item.AgencyAttr
    for (const filter of filterArray) {
      if (filter && !filters[filter.category].includes(filter.attr)) {
        filters[filter.category].push(filter.attr)
      }
    }
  }
  return filters
}

export const filterDataFunction = (checked, data) => {
  return data.filter((agency) => {
    if (agency.AgencyAttr && agency.AgencyAttr.length) {
      return checked.every((check) =>
        agency.AgencyAttr.map((val) => val && val.attr).includes(check)
      )
    }
    return null
  })
}
