import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles((_theme) => ({
  button: {
    alignSelf: 'center',
    backgroundColor: colors.brandOrange,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    margin: '2vh 0',
    textTransform: 'none',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    left: 'auto',
    width: '20%',
  },
  closeIcon: {
    color: colors.white,
  },
  drawer: {
    backgroundColor: colors.bgBlue,
    color: colors.white,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '50vh',
  },
  header: {
    alignItems: 'center',
    backgroundColor: colors.brandBlue,
    display: 'flex',
    height: '8vh',
    justifyContent: 'center',
    minHeight: '60px',
    width: '100%',
  },
  notchedOutline: {
    border: `0px solid ${colors.lightBlue}`,
  },
  root: {
    height: '50vh',
    minHeight: '300px',
  },
  searchContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '40vh',
    justifyContent: 'space-around',
    minHeight: '250px',
    padding: '2vh 5vw',
  },
  textField: {
    justifyContent: 'center',
    backgroundColor: colors.white,
    border: `3px solid ${colors.lightBlue}`,
    borderRadius: '5px',
    fontWeight: 'bold',
    margin: '0 0 2vh',
    width: '90%',
    '&focused': {
      border: `0px solid ${colors.lightBlue}`,

    }
  },
  title: {
    color: colors.white,
    fontFamily: 'Mont-Heavy',
    fontSize: '1.4rem',
    textTransform: 'uppercase',
  },
  titles: {
    alignSelf: 'left',
    fontFamily: 'Mont-Heavy',
    fontSize: '1rem',
    textAlign: 'left',
    width: '90%',
  },
}))
