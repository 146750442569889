const actions = {
  REQUEST_START: 'REQUEST_START',
  REQUEST_END: 'REQUEST_END',
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.REQUEST_START:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
      };
    case actions.REQUEST_END:
      return {
        ...state,
        ...action.value,
        loading: false,
      };
    default:
      return state;
  }
}

export { actions, reducer };
