import React, { useRef } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

import MarkerIcon from '../../images/marker.svg'
import BlueDot from '../../images/blue-dot.svg'

import { useStyles } from './styles'

const GMAPS_API_KEY = process.env.GATSBY_GMAPS_API_KEY

const Map = ({
  geoPoints,
  centerHasChanged,
  changeMapCenter,
  items,
  centerChange,
  page,
  standAlone,
  geoLocated,
  zoom,
  searchPoints,
}) => {
  const classes = useStyles()
  const mapRef = useRef()

  const PAGE_SIZE = 15

  const { isLoaded } = useJsApiLoader({
    id: standAlone ? 'detailsMap' : 'AgenciesMap',
    googleMapsApiKey: GMAPS_API_KEY,
  })

  const onLoad = React.useCallback(
    function callback(map) {
      if (!standAlone) {
        centerHasChanged(true)
      }
    },
    [centerHasChanged, standAlone]
  )

  if (isLoaded) {
    return (
      <GoogleMap
        ref={mapRef}
        mapContainerStyle={{
          width: '100%',
          height: '28vh',
          minHeight: '24vh',
        }}
        center={standAlone || !searchPoints.lat ? geoPoints : searchPoints}
        onDrag={!standAlone ? centerHasChanged : null}
        onZoomChanged={!standAlone ? centerHasChanged : null}
        onLoad={onLoad}
        zoom={zoom}
        options={{
          mapTypeControl: false,
          zoomControl: false,
          streetViewControl: false,
          rotateControl: false,
          scaleControl: false,
          fullScreenControl: true,
        }}
      >
        {centerChange ? (
          <Button
            variant="contained"
            onClick={() =>
              changeMapCenter(
                mapRef.current.state.map.center.lat(),
                mapRef.current.state.map.center.lng()
              )
            }
            className={classes.redoButton}
            label="Redo Search In This Area"
          >
            <Typography className={classes.buttonText}>
              Redo Search In This Area
            </Typography>
          </Button>
        ) : null}

        {items.map((i, index) => (
          <Marker
            key={index}
            icon={{ url: MarkerIcon }}
            position={{
              lat: parseFloat(i.Latitude),
              lng: parseFloat(i.Longitude),
            }}
            label={{
              text: !standAlone ? String(1 + index + PAGE_SIZE * page) : '1',
              color: !standAlone ? 'white' : 'transparent',
              fontWeight: 'bold',
              fontSize: '12px',
              className: classes.markerLabel,
            }}
          />
        ))}
        {!standAlone && geoLocated ? (
          <Marker
            position={geoPoints}
            icon={{
              url: BlueDot,
              scaledSize: new window.google.maps.Size(16, 16),
            }}
          />
        ) : null}
      </GoogleMap>
    )
  } else {
    return <Grid />
  }
}

export { Map }
