export const agencyStore = {
  data: [],
  filteredData: [],
  filters: {
    Demographics: [],
    Services: [],
    Languages: [],
    Others: [],
  },
  filtersApplied: [],
  hasMore: true,
  items: [],
  page: 0,
  refinedData: [],
  searchData: [],
  nameSearch: undefined,
  addressSearch : undefined,
  geoPoints: {lat: 34.059854, lng: -118.233833},
  searchPoints: {},
  isGeolocated: false,
  dataPractitioners: [],
}
