import { makeStyles } from '@material-ui/core/styles'

import {colors} from '../../theme'

export const useStyles = makeStyles(_theme => ({
  checkmarkIcon: {
    color: colors.green,
  },
  combined: {
    alignItems: 'center',
    borderBottom: `1px solid ${colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '0 0 2vh',
    padding: '2vh 0',
  },
  distanceText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  listItem: {
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: '5px',
    boxShadow: '3px 0px 10px 0px rgba(25,65,117,0.17)',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 1vw 2vh',
    padding: '2vh 4vw 2vh 3vw',
    width: '90%',
  },
  listItemCombined: {
    display: 'flex',
    flexDirection: 'row',
    margin: '2vh 0',
  },
  listItemCombinedTitle: {
    color: colors.brandBlue,
    fontSize: '0.9em',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  listItemCombinedText: {
    color: colors.textGrey,
    fontSize: '0.9em',
  },
  listItemText: {
    color: colors.textGrey,
    fontSize: '0.9em',
  },
  otherServicesRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    margin: '1vh 0',
  },
  phoneText: {
    color: colors.lightBlue,
    fontSize: '1em',
    fontWeight: 'bold',
  },
  servicesNameText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontWeight: 'bold',
    marginLeft: '5px'
  },
  title: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',    
    fontSize: '1em',
    fontWeight: 'bold',
    letterSpacing: '0',
    textTransform: 'capitalize',
    width: '80%',
  },
  updatedText: {
    color: colors.textGrey,
    fontSize: '0.9em',  
    fontStyle: 'italic',
    margin: '2vh 0 1vh'
  }
}))
