import { makeStyles } from '@material-ui/core/styles'

import {colors} from '../../theme'

export const useStyles = makeStyles(_theme => ({
  combined: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    margin: '0 0 1vh',
  },  
  listItem: {
    alignSelf: 'center',
    backgroundColor: colors.white,
    borderRadius: '5px',
    boxShadow: '3px 0px 10px 0px rgba(25,65,117,0.17)',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 1vw 2vh',
    padding: '2vh 5vw 2vh 5vw',
    width: '92%',
  },
  listItemText: {
    color: colors.textGrey,
    fontSize: '0.9em',
  },
  title: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    letterSpacing: '0',
    margin: '0 0 1vh',
    textTransform: 'capitalize',
  },
  itemTitle: {
    color: colors.brandBlue,
    fontSize: '1em',
    fontWeight: 'bold',
    letterSpacing: '0',
    margin: '0 2vw',
    textTransform: 'capitalize',
  },  
  top: {
    borderBottom: `1px solid ${colors.lightGrey}`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 0 2vh',
    padding: '0 0 2vh',
  },
}))
