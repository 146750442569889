import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles((_theme) => ({
  button: {
    backgroundColor: colors.brandOrange,
    color: colors.textBlue,
    fontFamily: 'Mont-Heavy',
    minHeight: '40px',
    margin: '0 6px',
    '&$focused': {
      backgroundColor: colors.brandOrange,
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    height: '50px',
    margin: '2vh 0',
    width: '50%',
  },
  filterBadge: {
    alignItems: 'center',
    backgroundColor: colors.textBlue,
    borderRadius: '1.25vh',
    color: colors.white,
    display: 'flex',
    height: '2.5vh',
    justifyContent: 'center',
    verticalAlign: 'center',
    width: '2.5vh',
  },
  filterBadgeText: {
    color: colors.white,
    fontSize: '0.7em',
    fontWeight: 'bold',
  },
  filterImg: {
    color: colors.textBlue,
    margin: '0 0 0 1vw',
    width: '70%',
  },
  filters: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'row',
    height: '10vh',
    marginBottom: '2vh',
    marginTop: '2vh',
    minHeight: '50px',
    padding: '0 4%',
    position: 'relative',
    width: '100%',
  },
  filtersButton: {
    backgroundColor: colors.brandOrange,
    boxShadow: 'none',
    color: colors.textBlue,
    fontFamily: 'Mont-Heavy',
    fontWeight: 'bold',
  },
  filtersLeft: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  filtersRight: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
  },
  fixedContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    width: '100%',
  },
  inline: {
    display: 'inline',
  },
  learnMoreText: {
    color: colors.bgBlue,
    fontFamily: 'Roboto',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    marginRight: 0,
    textAlign: 'right',
    textDecoration: 'underline',
    width: '60%',
  },
  list: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingBottom: '15vh',
    paddingTop: 0,
    position: 'relative',
  },
  noResults: {
    color: colors.textGrey,
    fontFamily: 'Mont-Bold',
    fontSize: '1.1em',
    letterSpacing: '0',
    margin: '5vh 4% 1vh',
    textAlign: 'center',
  },
  noResultsText: {
    color: colors.textGrey,
    fontFamily: 'Roboto',
    fontSize: '0.9em',
    letterSpacing: '0',
    margin: '0 8%',
    textAlign: 'center',
  },
  resultsText: {
    alignSelf: 'flex-start',
    alignText: 'left',
    backgroundColor: colors.backgroundGrey,
    color: colors.bgBlue,
    fontFamily: 'Mont-Heavy',
    fontWeight: 'bold',
    padding: '0 0 6px 5vw',
    width: '100%',
  },
  root: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '-1px',
    width: '100%',
  },
  searchButton: {
    alignItems: 'center',
    backgroundColor: colors.white,
    border: `2px solid ${colors.lightBlue}`,
    color: '#3e3e3e',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    minHeight: '48px',
    textAlign: 'left',
    textTransform: 'none',
    width: '100%',
    outline: '0 solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  searchContainer: {
    alignItems: 'flex-start',
    backgroundColor: colors.brandBlue,
    borderTop: `1px solid ${colors.white}`,
    color: colors.white,
    display: 'flex',
    flexDirection: 'column',
    padding: '2vh 4%',
    width: '100%',
  },
  searchTitle: {
    fontFamily: 'Mont-Heavy',
    fontWeight: 'bold',
    marginBottom: '8px',
  },
  textFieldContainer: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    margin: '0 3px',
  },
  likeH1: {
    fontSize: '1rem',
  },
}))
