export const agencyActions = {
  setData: state => values => {
    return {
      ...state,
      agency: {
        ...state.agency,
        ...values,
      },
    }
  },
}
