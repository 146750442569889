import { Grid, List, Typography } from '@material-ui/core'
import React from 'react'

import Header from '../../components/Header'
import PractitionerCard from '../../components/PractitionerCard'
import Seo from '../../components/Seo'
import { useStyles } from './styles'

const Practitioners = (props) => {
  const classes = useStyles()
  const agencyData = props.location.state
    ? props.location.state.agencyData
    : undefined

  return (
    <>
      <Header />
      <Seo
        title={
          agencyData
            ? `Practitioners of ${agencyData.CorpName}`
            : 'Practitioners'
        }
      />
      <Grid className={classes.fixedContainer}>
        <Grid className={classes.root}>
          <Grid className={classes.top}>
            <Typography className={classes.title}>
              {agencyData.CorpName}
            </Typography>
            {agencyData['SecondaryAgencyName'] ? (
              <Typography className={classes.italicText}>
                {`Also know as: ${agencyData['SecondaryAgencyName']}`}
              </Typography>
            ) : null}
          </Grid>
          <Typography className={classes.titleList}>
            Licensed Practitioners
          </Typography>
          <List className={classes.list}>
            {agencyData.practitioners
              ? agencyData.practitioners.map((i, index) => (
                  <PractitionerCard key={index} data={i} />
                ))
              : null}
          </List>
        </Grid>
      </Grid>
    </>
  )
}

export { Practitioners }
