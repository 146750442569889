import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({

  mainView: {
    alignItems: 'center',
    backgroundColor: colors.darkBg,
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    position: 'fixed',
    width: '100vw',
    zIndex: 999,
  },
}))
