import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Link } from '@reach/router'
import Fuse from 'fuse.js'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Geocode from 'react-geocode'
import useGeolocation from 'react-hook-geolocation'

import FilterIcon from '../../images/icons/filter_ic.svg'
import Header from '../../components/Header'
import AgencyCard from '../../components/AgencyCard'
import FilterDrawer from '../../components/FilterDrawer'
import Loader from '../../components/Loader'
import Map from '../../components/Map'
import SearchDrawer from '../../components/SearchDrawer'
import Seo from '../../components/Seo'
import useDataApi from '../../hooks/useDataApi'
import { ctx } from '../../store'
import {
  calculateDistance,
  filterDataFunction,
  filtersGenerator,
  getFilters,
} from '../../utils/dataManager'
import { useStyles } from './styles'
import { Typography } from '@material-ui/core'

const LA_COUNTY_API = process.env.GATSBY_LA_COUNTY_API_URL
const GMAPS_API_KEY = process.env.GATSBY_GMAPS_API_KEY

const PAGE_SIZE = 15

const Agencies = () => {
  const classes = useStyles()
  const geolocation = useGeolocation()
  const ref = useRef()

  const [isSearchDrawerOpen, setSearchDrawerOpen] = useState(false)
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false)
  const [scroll, setScroll] = useState({
    direction: 'bottom',
    lastScrollPos: 0,
  })
  const [centerChange, setCenterChange] = useState(false)

  const {
    agency: {
      data,
      filteredData,
      filtersApplied,
      refinedData,
      searchData,
      page,
      items,
      hasMore,
      addressSearch,
      nameSearch,
      geoPoints,
      isGeolocated,
      searchPoints,
    },
  } = useContext(ctx.store)
  const actions = useContext(ctx.actions)

  Geocode.setApiKey(GMAPS_API_KEY)
  Geocode.setLanguage('en')

  const [{ loading }, exec] = useDataApi(`${LA_COUNTY_API}agency`, 'GET', false)
  const [{ loading: loading2 }, execPract] = useDataApi(
    `${LA_COUNTY_API}practitioner`,
    'GET',
    false
  )

  useEffect(() => {
    const fetchData = async () => {
      const fetched = await exec()
      const fetchedPractitioners = await execPract()
      actions.agency.setData({
        data: fetched,
        dataPractitioners: fetchedPractitioners,
      })
    }

    if (data.length === 0) {
      fetchData()
    }
  }, [actions.agency, data.length])

  useEffect(() => {
    if (data && data.length > 0 && refinedData.length === 0) {
      let lat
      let lng
      let isGeolocated = false
      if (
        geolocation &&
        !geolocation.error &&
        geolocation.latitude &&
        geolocation.longitude
      ) {
        lat = geolocation.latitude
        lng = geolocation.longitude
        isGeolocated = true
      } else {
        lat = geoPoints.lat
        lng = geoPoints.lng
      }

      const refinedData = data

      const dataWithDistance = refinedData
        .map((agency) => ({
          ...agency,
          Distance: calculateDistance(
            lat,
            lng,
            agency.Latitude,
            agency.Longitude
          ),
          AgencyAttr: filtersGenerator(agency),
        }))
        .sort((m1, m2) => m1.Distance - m2.Distance)

      const filters = getFilters(dataWithDistance)

      actions.agency.setData({
        refinedData: dataWithDistance,
        filters,
        geoPoints: { lat, lng },
        items: dataWithDistance.slice(0, PAGE_SIZE),
        isGeolocated,
      })
    }
  }, [
    actions.agency,
    data,
    geoPoints.lat,
    geoPoints.lng,
    geolocation,
    refinedData.length,
  ])

  const moveForward = () => {
    const newPage = page + 1
    let newData
    if (filteredData.length > 0) {
      newData = filteredData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    } else if (searchData.length > 0) {
      newData = searchData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    } else {
      newData = refinedData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    }
    actions.agency.setData({
      page: newPage,
      items: newData,
      hasMore: newPage * PAGE_SIZE + 1 < refinedData.length,
    })
    ref.current.scrollTo(0, 0)
  }

  const moveBackward = () => {
    const newPage = page - 1
    let newData
    if (filteredData.length > 0) {
      newData = filteredData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    } else if (searchData.length > 0) {
      newData = searchData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    } else {
      newData = refinedData.slice(
        newPage * PAGE_SIZE,
        newPage * PAGE_SIZE + PAGE_SIZE
      )
    }
    actions.agency.setData({
      page: newPage,
      items: newData,
      hasMore: true,
    })
    ref.current.scrollTo(0, 0)
  }

  const searchFunction = async (searchValue, addressValue, force, points) => {
    let newData, addressSearched, geo, lat, lng, sortBy
    if ((addressValue && addressValue !== '') || force) {
      if (
        addressValue &&
        addressValue !== '' &&
        addressValue !== 'center of the map area' &&
        !addressValue.includes('Near to') &&
        addressValue !== 'Current Location'
      ) {
      //  window.gtag('event', 'search', { search_location: addressValue })
        
        
        const geocoder = new window.google.maps.Geocoder()
        //const geocoder = new google.maps.Geocoder()

  
      
        const response = await geocoder.geocode({
          address: addressValue,
        })
        
        lat = response.results[0].geometry.location.lat()
        lng = response.results[0].geometry.location.lng()
      } else {
        lat = !points ? geoPoints.lat : points.lat
        lng = !points ? geoPoints.lng : points.lng
      }
      const dataWithDistance = refinedData
        .map((ag) => ({
          ...ag,
          Distance: calculateDistance(
            geoPoints.lat,
            geoPoints.lng,
            ag.Latitude,
            ag.Longitude
          ),
          SearchDistance: calculateDistance(
            lat,
            lng,
            ag.Latitude,
            ag.Longitude
          ),
        }))
        .sort((m1, m2) => m1.SearchDistance - m2.SearchDistance)
      newData = dataWithDistance
      addressSearched = !force ? addressValue : 'center of the map area'
      geo = {
        lat,
        lng,
      }
      sortBy = 'SearchDistance'
    } else {
      newData = refinedData
      addressSearched = undefined
      geo = geoPoints
      sortBy = 'Distance'
    }

    const propsToBeSet = {
      refinedData: newData,
      page: 0,
      searchPoints: geo,
    }

    if (searchValue && searchValue !== '') {
      window.gtag('event', 'search', { search_agency: searchValue })

      const options = {
        includeScore: true,
        findAllMatches: true,
        keys: ['CorpName'],
      }
      const fuse = new Fuse(newData, options)
      const results = fuse.search(searchValue)
      const searchedData = results
        .filter((it) => it.score < 0.6)
        .map((el) => el.item)
        .sort((m1, m2) => m1[sortBy] - m2[sortBy])

      let filteredData = []
      if (filtersApplied.length) {
        filteredData = await filterDataFunction(filtersApplied, searchedData)
      }

      let defData = filteredData.length ? filteredData : searchedData
      defData = defData.sort((m1, m2) => m1[sortBy] - m2[sortBy])
      const items = defData.slice(0, PAGE_SIZE)

      actions.agency.setData({
        ...propsToBeSet,
        filteredData,
        filtersApplied: filtersApplied.length ? filtersApplied : [],
        searchData: searchedData,
        items,
        nameSearch: searchValue,
        addressSearch: addressSearched,
        hasMore: defData.length > PAGE_SIZE,
      })
    } else {
      let filteredData = []
      if (filtersApplied.length) {
        filteredData = await filterDataFunction(filtersApplied, newData)
      }
      let defData = filteredData.length ? filteredData : newData
      defData = defData.sort((m1, m2) => m1[sortBy] - m2[sortBy])
      const items = defData.slice(0, PAGE_SIZE)

      actions.agency.setData({
        ...propsToBeSet,
        filteredData,
        filtersApplied: filtersApplied.length ? filtersApplied : [],
        searchData: [],
        items,
        nameSearch: undefined,
        addressSearch: addressSearched,
        hasMore: defData.length > PAGE_SIZE,
      })
    }
    ref.current.scrollTo(0, 0)
    if (!force) {
      handleSearchDrawerState()
    } else {
      setCenterChange(false)
    }
  }

  const changeMapCenter = (lat, lng) => {
    const points = { lat, lng }
    searchFunction(nameSearch, undefined, true, points)
  }

  const filterData = (checked) => {
    if (checked.length) {
      const filteredData = filterDataFunction(
        checked,
        searchData.length ? searchData : refinedData
      )

      for (const filtered of checked) {
        window.gtag('event', 'filter', { filter_checked: filtered })
      }

      actions.agency.setData({
        filtersApplied: checked,
        filteredData: filteredData,
        hasMore: PAGE_SIZE < filteredData.length,
        page: 0,
        items: filteredData.slice(0, PAGE_SIZE),
      })
    } else {
      actions.agency.setData({
        filtersApplied: [],
        filteredData: [],
        hasMore: true,
        page: 0,
        items: searchData.length
          ? searchData.slice(0, PAGE_SIZE)
          : refinedData.slice(0, PAGE_SIZE),
      })
    }
    ref.current.scrollTo(0, 0)
  }

  const handleSearchDrawerState = () => {
    setSearchDrawerOpen(!isSearchDrawerOpen)
  }

  const handleFilterDrawerState = () => {
    setFilterDrawerOpen(!isFilterDrawerOpen)
  }

  const getSearchLabel = () => {
    if (!nameSearch && !addressSearch) {
      if (isGeolocated) {
        return 'Current location'
      } else {
        return 'Search by agency, address or ZIP'
      }
    } else if (!nameSearch) {
      return `Near to ${addressSearch}`
    } else if (!addressSearch) {
      if (isGeolocated) {
        return `${nameSearch} - Current location`
      } else {
        return `${nameSearch}`
      }
    } else {
      return `${nameSearch} - ${addressSearch}`
    }
  }

  const handleListScroll = (event) => {
    if (scroll.lastScrollPos > event.currentTarget.scrollTop) {
      setScroll({
        direction: 'bottom',
        lastScrollPos: event.currentTarget.scrollTop,
      })
    } else if (scroll.lastScrollPos < event.currentTarget.scrollTop) {
      setScroll({
        direction: 'top',
        lastScrollPos: event.currentTarget.scrollTop,
      })
    }
  }

  const centerHasChanged = (forced) => {
    setCenterChange(!forced ? true : false)
  }

  return (
    <Grid>
      <Seo title="Providers" />
      <Loader active={loading || loading2} />
      <Header />
      <Grid className={classes.fixedContainer}>
        <Grid className={classes.root}>
          <Grid className={classes.searchContainer}>
            <h1 className={classes.likeH1}>Search for a provider</h1>
            <Button
              variant="outlined"
              onClick={handleSearchDrawerState}
              className={classes.searchButton}
            >
              {getSearchLabel()}
            </Button>
          </Grid>
        </Grid>
        <div className={classes.list} ref={ref} onScroll={handleListScroll}>
          <Map
            items={items}
            geoPoints={geoPoints}
            searchPoints={searchPoints}
            centerHasChanged={centerHasChanged}
            changeMapCenter={changeMapCenter}
            centerChange={centerChange}
            page={page}
            geoLocated={isGeolocated}
            zoom={11}
          />
          <Grid className={classes.filters}>
            <Grid className={classes.filtersLeft}>
              <Button
                variant="contained"
                className={classes.filtersButton}
                endIcon={
                  !filtersApplied.length ? (
                    <img
                      alt="filter-img"
                      className={classes.filterImg}
                      src={FilterIcon}
                    />
                  ) : (
                    <Grid className={classes.filterBadge}>
                      <Typography className={classes.filterBadgeText}>
                        {filtersApplied.length}
                      </Typography>
                    </Grid>
                  )
                }
                onClick={handleFilterDrawerState}
              >
                Filters
              </Button>
            </Grid>
            <Grid className={classes.filtersRight}>
              <Link
                to={`/learn-more-about-this-tool/`}
                style={{
                  textDecoration: 'none',
                  justifyContent: 'flex-end',
                  display: 'flex',
                }}
                role="button"
              >
                <Typography className={classes.learnMoreText}>
                  Learn more about this tool
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Typography className={classes.resultsText}>Results</Typography>
          {items.map((i, index) => (
            <AgencyCard
              key={index}
              data={i}
              isGeolocated={isGeolocated}
              isSearched={addressSearch}
              index={index + PAGE_SIZE * page}
            />
          ))}
          {data.length && items.length ? (
            <Grid className={classes.buttons}>
              {page === 0 ? null : (
                <Button
                  variant="contained"
                  onClick={moveBackward}
                  className={classes.button}
                >
                  Previous
                </Button>
              )}
              {hasMore ? (
                <Button
                  variant="contained"
                  onClick={moveForward}
                  className={classes.button}
                >
                  Next
                </Button>
              ) : null}
            </Grid>
          ) : (
            <>
              <Typography className={classes.noResults}>
                No results found
              </Typography>
              <Typography className={classes.noResultsText}>
                Check your spelling and try again, or search by a different
                keyword.
              </Typography>
            </>
          )}
        </div>
        <SearchDrawer
          isOpen={isSearchDrawerOpen}
          setDrawer={handleSearchDrawerState}
          search={searchFunction}
          addressSearchValue={addressSearch}
          nameSearchValue={nameSearch}
          isGeolocated={isGeolocated}
        />
        <FilterDrawer
          isOpen={isFilterDrawerOpen}
          setDrawer={handleFilterDrawerState}
          filter={filterData}
        />
      </Grid>
    </Grid>
  )
}

export { Agencies }
