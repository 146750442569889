import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  fixedContainer: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    position: 'fixed',
    width: '100%',
  },
  italicText: {
    color: colors.textGrey,
    fontSize: '0.9em',
    fontStyle: 'italic',
    margin: '1vh 0',
  },
  list: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: 0,
    paddingBottom: '100px',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
  },
  root: {
    backgroundColor: colors.backgroundGrey,
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'scroll',
    position: 'relative',
    width: '100%',
  },
  title: {
    color: colors.brandBlue,
    fontSize: '1.2em',
    fontFamily: 'Mont-Heavy',
    letterSpacing: '0',
    textTransform: 'uppercase',
  },
  titleList: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    letterSpacing: '0',
    margin: '1vh 4% 2vh',
  },
  top: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '60px',
    padding: '1vh 4% 1vh',
    width: '100%',
  },
}))
