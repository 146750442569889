import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import { useStyles } from './styles'

const PractitionerCard = (data) => {
  const classes = useStyles()

  const practitioner = data.data

  return (
    <Grid className={classes.listItem} key={data.index}>
      <Grid className={classes.top}>
        <Typography className={classes.title}>
          {`${practitioner.FName} ${practitioner.LName}`}
        </Typography>
        <Typography className={classes.listItemText}>
          {practitioner.TypeOfPractitioner}
        </Typography>
      </Grid>
      <Grid className={classes.combined}>
        <Typography className={classes.itemTitle}>NPI:</Typography>
        <Typography className={classes.listItemText}>
          {practitioner.NPI}
        </Typography>
      </Grid>
      <Grid className={classes.combined}>
        <Typography className={classes.itemTitle}>
          California License Number:
        </Typography>
        <Typography className={classes.listItemText}>
          {practitioner.CalLicNum}
        </Typography>
      </Grid>
      <Grid className={classes.combined}>
        <Typography className={classes.itemTitle}>
          Cultural Competence Training:
        </Typography>
        <Typography className={classes.listItemText}>
          {practitioner.CultCompetenceTraining}
        </Typography>
      </Grid>
    </Grid>
  )
}

export { PractitionerCard }
