import { Grid, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Link } from '@reach/router'
import moment from 'moment'
import React from 'react'

import { useStyles } from './styles'

const AgencyCard = (data) => {
  const classes = useStyles()

  const agencyData = data.data

  return (
    <Grid className={classes.listItem} key={data.index}>
      <Link
        to={`/agencies/${agencyData.CorpID}-${agencyData.LocID}`}
        state={{ agencyData: data }}
        style={{ textDecoration: 'none' }}
        role="button"
        onClick={() =>
          window.gtag('event', 'click', {
            agency_detail: `${agencyData.CorpName}`,
          })
        }
      >
        <Typography className={`${classes.title} no_translate`}>
          {`${data.index + 1}. ${agencyData.CorpName}`}
        </Typography>
        <Grid className={classes.combined}>
          <Grid className={classes.verticalCombined}>
            {!agencyData.Address ? null : (
              <Typography className={`${classes.listItemText} no_translate`}>
                {agencyData.Address}
              </Typography>
            )}
            <Typography className={`${classes.listItemText} no_translate`}>
              {`${agencyData.City}, ${agencyData.Region}. ${agencyData.PostalCode}`}
            </Typography>
          </Grid>
          <Typography className={classes.distanceText}>
            {data.isGeolocated && !data.isSearched
              ? `${agencyData.Distance.toFixed(1)}mi`
              : ''}
          </Typography>
        </Grid>

        <Typography className={classes.phoneText}>
          <a
            className={classes.phoneText}
            href={`tel:${agencyData.PhoneNum}`}
            onClick={() =>
              window.gtag('event', 'click', {
                phone_number: `${agencyData.CorpName} - ${agencyData.PhoneNum}`,
              })
            }
          >
            {agencyData.PhoneNum}
          </a>
        </Typography>

        <Grid className={classes.listItemCombined}>
          <Typography className={classes.listItemCombinedTitle}>
            Languages:
          </Typography>
          <Typography className={classes.listItemCombinedText}>
            {agencyData.Languages}
          </Typography>
        </Grid>
        {agencyData.AgencyAttr.filter(
          (agency) => agency && agency.category === 'Services'
        ).length ? (
          <Grid className={classes.listItemCombined}>
            <Typography className={classes.listItemCombinedTitle}>
              Services Provided:
            </Typography>
            <Typography className={classes.listItemCombinedText}>
              {agencyData.AgencyAttr.filter(
                (agency) => agency && agency.category === 'Services'
              )
                .map((ag) => {
                  return ag.attr
                })
                .join(', ')}
            </Typography>
          </Grid>
        ) : null}
        {agencyData.AgencyAttr.filter(
          (agency) => agency && agency.category === 'Others'
        ).length
          ? agencyData.AgencyAttr.filter(
              (agency) => agency && agency.category === 'Others'
            ).map((item) => (
              <Grid className={classes.otherServicesRow}>
                <CheckCircleIcon className={classes.checkmarkIcon} />
                <Typography className={classes.servicesNameText}>
                  {item.attr}
                </Typography>
              </Grid>
            ))
          : null}
        {agencyData.AgencyAttr.filter(
          (agency) => agency && agency.category === 'Services'
        ).length &&
        agencyData.AgencyAttr.filter(
          (agency) => agency && agency.category === 'Services'
        ).find((prop) => !prop.attr.includes('DUI')) ? (
          <Typography className={classes.updatedText}>
            {`Last Updated: ${moment(agencyData.LastUpdated).format(
              'hh:mm a MM/DD/YYYY'
            )}`}
          </Typography>
        ) : null}
      </Link>
    </Grid>
  )
}

export { AgencyCard }
