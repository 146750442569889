import React, { useContext, useEffect, useState } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Checkbox,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import isEqual from 'lodash.isequal'

import Close from '../../images/icons/close2.svg'
import { ctx } from '../../store'
import { colors } from '../../theme'
import ButtonComponent from '../ButtonComponent'

import { useStyles } from './styles'

const FilterDrawer = ({ isOpen, setDrawer, filter }) => {
  const classes = useStyles()
  const {
    agency: { filters, filtersApplied },
  } = useContext(ctx.store)

  const [checked, setChecked] = useState([])
  const [expanded, setExpanded] = useState(null)

  useEffect(() => {
    setChecked(filtersApplied)
  }, [filtersApplied, isOpen])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const onSubmit = () => {
    filter(checked)
    setDrawer()
  }

  const onReset = () => {
    setChecked([])
  }

  const onExpanded = (value) => {
    setExpanded(value === expanded ? null : value)
  }

  return (
    <Drawer
      anchor={'top'}
      open={isOpen}
      onClose={setDrawer}
      className={classes.root}
    >
      <Grid className={classes.drawer}>
        <Grid className={classes.header}>
          <Typography className={classes.title}>Filter by</Typography>
          <IconButton className={classes.closeButton} onClick={setDrawer}>
            <img
              role="none"
              alt=""
              className={classes.iconCloseButton}
              src={Close}
            />
          </IconButton>
        </Grid>
        <Grid className={classes.filtersContainer}>
          {Object.keys(filters).map((category, index) => (
            <Accordion
              key={index}
              className={classes.accordionMain}
              expanded={index === expanded}
              onChange={() => onExpanded(index)}
            >
              <AccordionSummary
                className={classes.accordion}
                expandIcon={
                  <ExpandMoreIcon className={classes.accordionIcon} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>{category}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionMain}>
                <List dense className={classes.list}>
                  {filters[category]
                    .filter((fi) => fi)
                    .map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value}`
                      return (
                        <ListItem
                          key={value}
                          button
                          onClick={handleToggle(value)}
                        >
                          <Checkbox
                            edge="end"
                            id={value}
                            onChange={handleToggle(value)}
                            checked={checked.includes(value)}
                            className={classes.checkbox}
                            style={{
                              color: colors.lightBlue,
                            }}
                          />
                          <ListItemText
                            className={classes.itemText}
                            id={labelId}
                            primary={value}
                          />
                        </ListItem>
                      )
                    })}
                </List>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid className={classes.buttons}>
          {!isEqual(checked.sort(), filtersApplied.sort()) ? (
            <ButtonComponent
              text={
                !checked.length && filtersApplied.length
                  ? 'Confirm Reset'
                  : 'Apply Filters'
              }
              type="orange"
              id={
                !checked.length && filtersApplied.length
                  ? 'confirm-reset'
                  : 'apply-filters'
              }
              onClick={onSubmit}
            />
          ) : null}
          {checked.length ? (
            <Button
              className={classes.resetButton}
              variant="text"
              onClick={onReset}
            >
              Reset Filters
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Drawer>
  )
}

export { FilterDrawer }
