import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  accordion: {
    backgroundColor: colors.bgBlue,
    color: colors.white,
    margin: '0 1.5rem 0 1rem',
    padding: '0',
  },
  accordionIcon: {
    color: colors.white,
  },
  accordionMain: {
    backgroundColor: colors.bgBlue,
    color: colors.white,
    maxHeight: '250px',
    width: '100%',
  },
  button: {
    alignSelf: 'center',
    backgroundColor: colors.brandOrange,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    margin: '0 0 3vh',
    textTransform: 'none',
    width: '40%',
  },
  buttons: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 3vh',
  },
  checkbox: {
    color: colors.lightBlue,
    marginRight: '2px',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    left: 'auto',
    width: '20%',
    color: 'white',
  },
  closeIcon: {
    color: colors.white,
    height: '30px',
  },
  iconCloseButton: {
    height: '20px',
  },
  drawer: {
    backgroundColor: colors.bgBlue,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    height: '100vh',
  },
  filtersContainer: {
    alignItems: 'center',
    backgroundColor: colors.bgBlue,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    alignItems: 'center',
    backgroundColor: colors.brandBlue,
    display: 'flex',
    height: '8vh',
    justifyContent: 'center',
    minHeight: '60px',
    width: '100%',
    borderBottom: '1px solid white',
  },
  heading: {
    fontFamily: 'Mont-Heavy',
    fontSize: '16px',
  },
  itemText: {
    fontSize: '16px',
  },
  list: {
    color: colors.white,
    backgroundColor: colors.bgBlue,
    maxHeight: '200px',
    overflowY: 'scroll',
    width: '100vw',
  },
  resetButton: {
    alignSelf: 'center',
    color: colors.white,
    fontFamily: 'Mont-Heavy',
    fontSize: '1em',
    margin: 0,
    textAlign: 'center',
    textTransform: 'none',
    top: 'auto',
    width: '60%',
    outline: '0 solid transparent',
    '&:focus': {
      outline: 'none',
    },
  },
  root: {
    height: '100%',
  },
  textField: {
    width: '80%',
  },
  title: {
    color: colors.white,
    fontSize: '20px',
    fontFamily: 'Mont-Heavy',
    textTransform: 'uppercase',
  },
  titles: {
    alignSelf: 'left',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    textAlign: 'left',
  },
}))
