import { Grid } from '@material-ui/core'
import React from 'react'
import Lottie from 'react-lottie'

import LoaderAnimation from '../../animations/loader.json'

import { useStyles } from './styles'

const Loader = ({ active }) => {
  const classes = useStyles()

  if (!active) {
    return <></>
  }

  
  return (
    <Grid className={classes.mainView}>
      <Lottie
        options={{
          animationData: LoaderAnimation,
          loop: true,
          autoplay: true,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        }}
        style={{ position: 'absolute', zIndex: 999 }}
        width={100}
        height={100}
      />
    </Grid>
  )
}

export { Loader }
