import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles((_theme) => ({
  markerLabel: {
    marginTop: '-12.5px',
  },
  redoButton: {
    backgroundColor: colors.white,
    border: `2px solid ${colors.brandOrange}`,
    left: '21%',
    minHeight: '30px',
    position: 'absolute',
    textTransform: 'none',
    top: '5%',
    width: '58%',
  },
  blueDot: {
    width: '20px',
    height: '20px',
  },
  buttonText: {
    fontSize: '0.8rem',
    color: colors.brandBlue,
    fontFamily: 'Mont-Heavy',
  },
}))
