import { Grid, Typography } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Link } from '@reach/router'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import useGeolocation from 'react-hook-geolocation'

import ShareIcon from '../../images/share.png'

import Header from '../../components/Header'
import Loader from '../../components/Loader'
import Map from '../../components/Map'
import Seo from '../../components/Seo'
import useDataApi from '../../hooks/useDataApi'
import { ctx } from '../../store'
import {
  calculateDistance,
  filtersGenerator,
  getFilters,
} from '../../utils/dataManager'

import { useStyles } from './styles'

const LA_COUNTY_API = process.env.GATSBY_LA_COUNTY_API_URL
const BASE_URL = process.env.GATSBY_BASE_URL

const PAGE_SIZE = 15

const AgencyDetails = (props) => {
  const classes = useStyles()
  const geolocation = useGeolocation()
  const agencyData = props.location.state
    ? props.location.state.detailsData
    : undefined
  const [detailsData, setDetailsData] = useState(agencyData)
  const [practitionersData, setPractitionersData] = useState([])
  const [os, setOs] = useState('')

  const {
    agency: {
      data,
      refinedData,
      geoPoints,
      isGeolocated,
      addressSearch,
      dataPractitioners,
    },
  } = useContext(ctx.store)
  const actions = useContext(ctx.actions)

  const [{ loading }, exec] = useDataApi(`${LA_COUNTY_API}agency`, 'GET', false)
  const [{ loading: loading2 }, execPract] = useDataApi(
    `${LA_COUNTY_API}practitioner`,
    'GET',
    false
  )
  useEffect(() => {
    const fetchData = async () => {
      const fetched = await exec()
      const fetchedPractitioners = await execPract()
      actions.agency.setData({
        data: fetched,
        dataPractitioners: fetchedPractitioners,
      })
    }

    if (data.length === 0) {
      fetchData()
    }
  }, [actions.agency, data.length])

  useEffect(() => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setOs('ios')
    } else {
      setOs('android')
    }

    if (data && data.length > 0 && refinedData.length === 0) {
      let lat
      let lng
      let isGeolocated = false
      if (
        geolocation &&
        !geolocation.error &&
        geolocation.latitude &&
        geolocation.longitude
      ) {
        lat = geolocation.latitude
        lng = geolocation.longitude
        isGeolocated = true
      } else {
        lat = geoPoints.lat
        lng = geoPoints.lng
      }

      const refinedData = data

      const dataWithDistance = refinedData
        .map((agency, index) => ({
          ...agency,
          Distance: calculateDistance(
            lat,
            lng,
            agency.Latitude,
            agency.Longitude
          ),
          AgencyAttr: filtersGenerator(agency),
          key: index,
        }))
        .sort((m1, m2) => m1.Distance - m2.Distance)

      const foundData = dataWithDistance.filter(
        (d) => `${d.CorpID}-${d.LocID}` === props.id
      )[0]

      setDetailsData(foundData)

      const foundPractitionerData = dataPractitioners.filter(
        (d) => `${d.CorpID}-${d.LocID}` === props.id
      )
      setPractitionersData(foundPractitionerData)

      const filters = getFilters(dataWithDistance)

      actions.agency.setData({
        refinedData: dataWithDistance,
        filters,
        geoPoints: { lat: geolocation.latitude, lng: geolocation.longitude },
        items: dataWithDistance.slice(0, PAGE_SIZE),
        isGeolocated,
      })
    } else {
      const foundData = refinedData.filter(
        (d) => `${d.CorpID}-${d.LocID}` === props.id
      )[0]
      setDetailsData(foundData)

      const foundPractitionerData = dataPractitioners.filter(
        (d) => `${d.CorpID}-${d.LocID}` === props.id
      )
      setPractitionersData(foundPractitionerData)
    }
  }, [
    data,
    actions.agency,
    dataPractitioners,
    geoPoints.lat,
    geoPoints.lng,
    geolocation,
    props.id,
    refinedData,
  ])

  const getDirections = () => {
    const url =
      os === 'android'
        ? 'https://maps.google.com/?'
        : 'https://maps.apple.com/?'
    const method = isGeolocated ? 'daddr=' : 'q='
    const params = `${detailsData.Latitude},${detailsData.Longitude}`
    return url + method + params
  }

  const shareAgency = () => {
    window.gtag('event', 'share', {
      agency_name: detailsData.CorpName,
    })
    if (navigator.share) {
      navigator.share({
        title: detailsData.CorpName,
        text: detailsData.CorpName,
        url: `${BASE_URL}agencies/${props.id}`,
      })
    }
  }

  return (
    <Grid>
      <Seo title={detailsData ? `${detailsData.CorpName}` : 'Provider'} />
      <Loader active={loading || loading2} />
      <Header />
      <Grid className={classes.fixedContainer}>
        {detailsData ? (
          <Grid className={classes.root}>
            <Grid className={classes.top}>
              <Typography className={classes.title}>
                {detailsData.CorpName}
              </Typography>
              {detailsData['SecondaryAgencyName'] &&
              detailsData['SecondaryAgencyName'] !== 'NULL' ? (
                <Grid className={classes.secondaryName}>
                  <Typography className={`${classes.italicText} no_translate`}>
                    Also know as:
                  </Typography>
                  <Typography className={classes.italicName}>
                    {`${detailsData['SecondaryAgencyName']}`}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid className={classes.combinedGrid}>
              {detailsData.PhoneNum ? (
                <Typography className={classes.phoneText}>
                  <a
                    role="none"
                    className={classes.phoneText}
                    href={`tel:${detailsData.PhoneNum}`}
                    onClick={() =>
                      window.gtag('event', 'click', {
                        phone_number: `${detailsData.CorpName} - ${detailsData.PhoneNum}`,
                      })
                    }
                  >
                    {detailsData.PhoneNum}
                  </a>
                </Typography>
              ) : (
                <Grid />
              )}
              <Grid className={classes.shareGrid} onClick={shareAgency}>
                <Typography className={classes.boldText}>Share</Typography>
                <img
                  alt="share-img"
                  className={classes.shareImg}
                  src={ShareIcon}
                />
              </Grid>
            </Grid>
            <Grid className={classes.combined}>
              <Grid className={classes.verticalCombined}>
                {!detailsData.Address ? null : (
                  <Typography
                    className={`${classes.listItemText} no_translate`}
                  >
                    {detailsData.Address}
                  </Typography>
                )}
                <Typography className={`${classes.listItemText} no_translate`}>
                  {`${detailsData.City}, ${detailsData.Region}. ${detailsData.PostalCode}`}
                </Typography>
              </Grid>
              <Typography className={classes.distanceText}>
                {isGeolocated && !addressSearch
                  ? `${detailsData.Distance.toFixed(1)}mi`
                  : ''}
              </Typography>
            </Grid>
            <a
              href={getDirections()}
              className={classes.links}
              style={{ fontWeight: 'bold' }}
              role="none"
            >
              <Typography className={classes.linkText}>
                Get Directions
              </Typography>
            </a>
            <Grid className={classes.servicesGrid}>
              {detailsData.AgencyAttr.filter(
                (agency) => agency && agency.category === 'Others.'
              ).length
                ? detailsData.AgencyAttr.filter(
                    (agency) => agency && agency.category === 'Others.'
                  ).map((item, index) => (
                    <Grid className={classes.otherServicesRow} key={index}>
                      <CheckCircleIcon className={classes.checkmarkIcon} />
                      <Typography className={classes.servicesNameText}>
                        {item.attr}
                      </Typography>
                    </Grid>
                  ))
                : null}
            </Grid>
            <Map
              items={[
                {
                  Latitude: detailsData.Latitude,
                  Longitude: detailsData.Longitude,
                },
              ]}
              geoPoints={{
                lat: detailsData.Latitude,
                lng: detailsData.Longitude,
              }}
              standAlone={true}
              zoom={15}
            />
            {detailsData.AgencyAttr.filter(
              (agency) => agency && agency.category === 'Population'
            ).length ? (
              <Grid className={classes.listItemCombined}>
                <Typography className={classes.listItemCombinedTitle}>
                  Population Served
                </Typography>
                <Typography className={classes.listItemCombinedText}>
                  {detailsData.AgencyAttr.filter(
                    (agency) => agency && agency.category === 'Population'
                  )
                    .map((ag) => {
                      return ag.attr
                    })
                    .join(', ')}
                </Typography>
              </Grid>
            ) : null}
            {detailsData.AgencyAttr.filter(
              (agency) => agency && agency.category === 'Services'
            ).length ? (
              <Grid className={classes.listItemCombined}>
                <Typography className={classes.listItemCombinedTitle}>
                  Services Provided
                </Typography>
                <Typography className={classes.listItemCombinedText}>
                  {detailsData.AgencyAttr.filter(
                    (agency) => agency && agency.category === 'Services'
                  )
                    .map((ag) => {
                      return ag.attr
                    })
                    .join(', ')}
                </Typography>
              </Grid>
            ) : null}
            <Grid className={classes.listItemCombined}>
              <Typography className={classes.listItemCombinedTitle}>
                Languages
              </Typography>
              <Typography className={classes.listItemCombinedText}>
                {detailsData.AgencyAttr.filter(
                  (agency) => agency && agency.category === 'Languages'
                )
                  .map((ag) => {
                    return ag.attr
                  })
                  .join(', ')}
              </Typography>
            </Grid>
            {detailsData.SiteHrs &&
            detailsData.SiteHrs.split('; ')[0] !== 'NULL' ? (
              <Grid className={classes.hoursGrid}>
                <Typography className={classes.listItemCombinedTitle}>
                  Business Hours
                </Typography>
                {detailsData.SiteHrs.split('; ').map((days, index) => {
                  const splittedDays = days.split(': ')
                  return (
                    <Grid className={classes.daysRow} key={index}>
                      <Typography className={classes.daysText}>
                        {splittedDays[0]}
                      </Typography>
                      <Typography className={classes.hoursText}>
                        {splittedDays[1]}
                      </Typography>
                    </Grid>
                  )
                })}
              </Grid>
            ) : null}
            {detailsData.Website !== 'NULL' ? (
              <a
                href={detailsData.Website}
                className={classes.links}
                style={{ fontWeight: 'bold' }}
                target={'_blank'}
                rel="noopener noreferrer"
                role="none"
                onClick={() =>
                  window.gtag('event', 'click', {
                    agency_website: `${detailsData.CorpName} - ${detailsData.Website}`,
                  })
                }
              >
                <Typography className={classes.linkText}>
                  {detailsData.Website}
                </Typography>
              </a>
            ) : null}
            {practitionersData.length ? (
              <Link
                to={`/agencies/${props.id}/practitioners`}
                state={{
                  agencyData: {
                    ...detailsData,
                    practitioners: practitionersData,
                  },
                }}
                style={{ textDecoration: 'none' }}
                className={classes.links}
                onClick={() =>
                  window.gtag('event', 'click', {
                    practitioners_list: `${detailsData.CorpName}`,
                  })
                }
              >
                <Typography className={classes.linkText}>
                  Licensed Practitioners
                </Typography>
              </Link>
            ) : null}
            {detailsData.AgencyAttr.filter(
              (agency) => agency && agency.category === 'Services'
            ).length &&
            detailsData.AgencyAttr.filter(
              (agency) => agency && agency.category === 'Services'
            ).find((prop) => !prop.attr.includes('DUI')) ? (
              <Typography className={classes.updatedText}>
                {`Last Updated: ${moment(detailsData.LastUpdated).format(
                  'hh:mm a MM/DD/YYYY'
                )}`}
              </Typography>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  )
}

export { AgencyDetails }
